import {
  toSafeInteger,
  isObject,
  isEmpty,
  capitalize,
  toLower,
  lowerCase,
  identity,
  pickBy,
  cloneDeep,
} from "lodash";
import { format } from "date-fns";
import currency from "currency.js";
import { GET_CONFIG } from "@/core/lib/pos.lib";
import { saveData, getData } from "@/core/services/local.service.js";

const validateEmail = (email) => {
  //eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

let pos_config_running = false;

class MainSetting {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          listTitle: [
            {
              value: "mr",
              text: "Mr.",
            },
            {
              value: "ms",
              text: "Ms.",
            },
            {
              value: "mrs",
              text: "Mrs.",
            },
            {
              value: "miss",
              text: "Miss.",
            },
            {
              value: "dr",
              text: "Dr.",
            },
          ],
          listAccounting: [
            {
              value: "none",
              text: "NONE",
            },
            {
              value: "fifo",
              text: "FIFO (First In First Out)",
            },
            {
              value: "fefo",
              text: "FEFO (First Expire First Out)",
            },
            {
              value: "lifo",
              text: "LIFO (Last In First Out)",
            },
          ],
          pageLoading: false,
          listRadioCondition: [
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ],
          vrules: {
            required(val, field, isInteger) {
              if (isObject(val) && isEmpty(val)) {
                return `${field} is required`;
              }
              if (isInteger && parseInt(val) <= 0) {
                return `${field} is required.`;
              }
              return !!val || `${field}  is required`;
            },
            validateQty(val, maxQty, field) {
              let newQty = toSafeInteger(val);
              let newAvailable = toSafeInteger(maxQty);
              if (newQty > newAvailable) {
                return `${field} is must be less than or equal to ${newAvailable}.`;
              }
              return true;
            },
            validEmail(val, field) {
              if (val) {
                if (validateEmail(val)) {
                  return true;
                }
                return `${field} must be valid`;
              }
              return true;
            },
            validFloat(val, field) {
              if (val) {
                if (/^\d*\.?\d*$/.test(val)) {
                  return true;
                }
                return `${field} must be valid`;
              }
              return true;
            },
            validNumeric(val, field) {
              if (val) {
                if (/^\d+$/.test(val)) {
                  return true;
                }
                return `${field} must be valid (only digits) & greater than 0`;
              }
              return true;
            },
            maxLength(val, field, maxLen) {
              if (val) {
                val = val.toString();
                if (val.length <= maxLen) {
                  return true;
                }
                return `${field} length must be less than or equal to ${maxLen}`;
              }
              return true;
            },
            minLength(val, field, minLen) {
              if (val) {
                val = val.toString();
                if (val.length >= minLen) {
                  return true;
                }
                return `${field} length must be greater than or equal to ${minLen}`;
              }
              return true;
            },
            lessThan(val, field, len) {
              if (val) {
                val = parseInt(val);
                if (val <= len) {
                  return true;
                }
                return `${field} must be less than or equal to ${len}`;
              }
              return true;
            },
            greaterThan(val, field, minLen) {
              if (val) {
                val = val.toString();
                if (val.length >= minLen) {
                  return true;
                }
                return `${field} length must be greater than or equal to ${minLen}`;
              }
              return true;
            },
            deleteValidation(val, field, barcode) {
              if (val) {
                val = val.toString();
                barcode = barcode.toString();
                if (val === barcode) {
                  return true;
                }
                return `${field} must be equal to ${barcode}`;
              }
              return true;
            },
            confirmPassword(val, field, old) {
              if (val) {
                val = val.toString();
                old = old.toString();
                if (val === old) {
                  return true;
                }
                return `${field} you entered don't match to new password`;
              }
              return true;
            },
          },
          currencyText: "MYR",
          currencySymbol: "RM ",
        };
      },
      methods: {
        isNotEmpty(param) {
          return !isEmpty(param);
        },
        isEmpty(param) {
          return isEmpty(param);
        },
        posConfig() {
          if (pos_config_running) {
            return false;
          }
          const pos_config = getData("pos-config");
          if (isEmpty(pos_config)) {
            pos_config_running = true;
            GET_CONFIG()
              .then((data) => {
                saveData("pos-config", data);
              })
              .catch((error) => {
                this.logError(error);
              });
          }
        },
        goBackStepper() {
          this.stepper--;
        },
        deepEmpty(param) {
          return cloneDeep(pickBy(param, identity));
        },
        formatDateTime(dateTime) {
          if (!dateTime) {
            return "N/A";
          }
          return format(new Date(dateTime), "dd/MM/yyyy hh:mm a");
        },
        formatLongDateTime(dateTime) {
          if (!dateTime) {
            return "N/A";
          }
          return format(new Date(dateTime), "do MMMM yyyy hh:mm a");
        },
        formatTime(time) {
          if (!time) {
            return "N/A";
          }
          return format(new Date(time), "hh:mm a");
        },
        formatDate(date) {
          if (!date) {
            return "N/A";
          }
          return format(new Date(date), "dd/MM/yyyy");
        },
        formatQuantity(value) {
          return currency(value, { precision: 2 }).value;
        },
        lower_case(string) {
          return lowerCase(string);
        },
        getStatusColor(param) {
          switch (this.lower_case(param)) {
            case "all":
              return "indigo--text text--darken-3";
            case "draft":
              return "blue--text";
            case "approved":
              return "green--text";
            case "accepted":
              return "green--text";
            case "completed":
              return "green--text";
            case "closed":
              return "red--text text--accent-2";
            case "rejected":
              return "red--text";
            case "cancelled":
              return "yellow--text text--darken-1";
            case "partially received":
              return "orange--text";
            case "pending":
              return "amber--text text--darken-4";
            case "in progress":
              return "orange--text";
            case "inprogress":
              return "orange--text";
            case "fully received":
              return "teal--text";
            case "received":
              return "cyan--text text--darken-1";

            case "force closed":
              return "red--text";
            case "Rejected":
              return "red--text text--darken-4";
            case "sent for approval":
              return "orange--text  text--darken-2";
            default:
              return "blue--text";
          }
        },
        getStatusBGColor(param) {
          switch (this.lower_case(param)) {
            case "all":
              return "indigo darken-3";
            case "draft":
              return "blue";
            case "approved":
              return "green";
            case "accepted":
              return "green";
            case "completed":
              return "green";
            case "closed":
              return "red accent-2";
            case "rejected":
              return "red";
            case "cancelled":
              return "yellow darken-1";
            case "partially received":
              return "orange";
            case "pending":
              return "amber darken-4";
            case "in progress":
              return "orange";
            case "inprogress":
              return "orange";
            case "fully received":
              return "teal";
            case "received":
              return "cyan darken-1";

            case "force closed":
              return "red";
            case "Rejected":
              return "red darken-4";
            case "sent for approval":
              return "orange  darken-2";
            default:
              return "blue";
          }
        },
        formatPrice(value) {
          return currency(value, { symbol: "", precision: 2 }).format();
        },
        convert_base_64(file) {
          return new Promise((resolve, reject) => {
            try {
              const reader = new FileReader();
              reader.addEventListener(
                "load",
                function () {
                  resolve(reader.result);
                },
                false
              );
              reader.readAsDataURL(file);
            } catch (error) {
              reject(error);
            }
          });
        },
        formatCurrency(value) {
          const price = this.formatPrice(value);
          return currency(price, { symbol: this.currencySymbol, precision: 2 }).format();
        },
        multiplyCurrency(value, multiplier) {
          return currency(value).multiply(multiplier).value;
        },
        validateForm(formRef) {
          const formErrors = [];
          const { inputs } = formRef;
          for (let i = 0; i < inputs.length; i++) {
            const { errorBucket } = inputs[i];
            for (let z = 0; z < errorBucket.length; z++) {
              const errorMessage = capitalize(toLower(errorBucket[z]));
              formErrors.push({
                model: true,
                message: errorMessage,
              });
            }
          }
          return formErrors;
        },
        goBack() {
          this.$router.go(-1);
        },
        async logError(error) {
          try {
            console.warn(error);
          } catch (error) {
            console.warn(error);
          }
        },
      },
      mounted() {
        this.posConfig();
      },
      computed: {
        stringUnique() {
          let result = "";
          let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
          let charactersLength = characters.length;
          for (let i = 0; i < 10; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
          }
          return result;
        },
      },
      errorCaptured(err, vm, info) {
        this.logError({ err, vm, info });
      },
    });
  }
}

export default new MainSetting();
