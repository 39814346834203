import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const LOGIN = "login";
export const LOGOUT = "logout";
export const PROFILE = "profile";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_USER = "setUser";
export const SET_AUTH = "setAuth";
export const SET_ERROR = "setError";

const state = {
  errors: [],
  user: JwtService.getCurrentUser(),
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch((error) => {
          // alert(1)
          // context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  },
  [PROFILE](context) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("user/profile")
        .then(({ data }) => {
          context.commit(SET_USER, data);
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("auth/logout")
        .then(() => {
          context.commit(PURGE_AUTH);
          resolve(true);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error);
          resolve(false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_USER](state, data) {
    state.user = data;
    JwtService.saveUser(data);
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.errors = {};
    JwtService.saveToken(data);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
